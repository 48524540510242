// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boda-js": () => import("/opt/build/repo/src/pages/boda.js" /* webpackChunkName: "component---src-pages-boda-js" */),
  "component---src-pages-callback-js": () => import("/opt/build/repo/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-firmas-js": () => import("/opt/build/repo/src/pages/firmas.js" /* webpackChunkName: "component---src-pages-firmas-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("/opt/build/repo/src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */)
}

